<template>
  <UiInput
    :model-value="modelValue"
    :type="'date'"
    :label="label"
    :name="name"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    :error="error"
    @update:model-value="update($event)"
  />
</template>

<script>
import UiInput from '@/components/ui/UiInput.vue'

export default {
  name: 'UiDate',
  components: { UiInput },
  props: {
    modelValue: null,
    type: {
      type: String,
      default: 'text',
    },
    name: String,
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value'],
  methods: {
    update(value) {
      this.$emit('update:model-value', value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
