<template>
  <UiSelect
    :model-value="value"
    :name="name"
    :label="label"
    :placeholder="placeholder"
    :options="_getCountries"
    :required="required"
    :disabled="disabled"
    :error="error"
    :value-label="'name'"
    :value-key="'id'"
    @update:model-value="update"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import UiSelect from '@/components/ui/UiSelect.vue'

export default {
  name: 'MainCountries',
  components: { UiSelect },
  props: {
    value: null,
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  computed: {
    ...mapGetters(['_getCountries']),
  },
  mounted() {
    this._setCountries()
  },
  methods: {
    ...mapActions(['_setCountries']),
    update(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
