<template>
  <label
    ref="select"
    class="ui-select"
    :class="{ 'ui-select--error': error, 'ui-select--focus': isFocused }"
    :data-name="name"
  >
    <div v-if="label" class="ui-select__label">
      <span>{{ label }}</span>
      <span v-if="required" class="ui-select__sign">*</span>
    </div>
    <div class="ui-select__item">
      <div class="ui-select__body">
        <input
          v-model="getSelectedValueLabel"
          :placeholder="placeholder"
          :name="name"
          :id="name"
          :required="required"
          :disabled="disabled"
          @focus="focus"
          @blur="blur"
        />
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ui-select__arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 9l6 6 6-6" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <label
          for="name"
          class="ui-select__body-mask"
          @click="isFocused = !disabled && !isFocused"
        ></label>
      </div>
      <div v-show="isFocused" class="ui-select__options">
        <template v-if="options.length">
          <div
            v-for="(option, optionInd) in options"
            :key="optionInd"
            :class="{ 'ui-select__option--selected': isSelected(option) }"
            class="ui-select__option"
            @click.stop.prevent="select(option)"
          >
            {{ getValueLabel(option) }}
          </div>
        </template>
        <template v-else>
          <div class="ui-select__option">
            {{ $t('labels.empty') }}
          </div>
        </template>
      </div>
    </div>
    <div v-if="sublabel" class="ui-select__sublabel">
      <span>{{ sublabel }}</span>
    </div>
    <div v-show="error" class="ui-select__error">
      {{ error }}
    </div>
  </label>
</template>

<script>
export default {
  name: 'UiSelect',
  props: {
    modelValue: null,
    name: String,
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    valueLabel: {
      type: String,
      default: '',
    },
    valueKey: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value', 'focus', 'blur'],
  data() {
    return {
      isFocused: false,
    }
  },
  computed: {
    getSelectedValueLabel() {
      if (this.multiple) {
        return (this.modelValue || [])
          .map((selectedOptionKey) =>
            this.getValueLabel(this.getOption(selectedOptionKey)),
          )
          .join(', ')
      } else {
        return this.modelValue
          ? this.getValueLabel(this.getOption(this.modelValue))
          : ''
      }
    },
    getOption() {
      return (key) => {
        return this.options.find((option) => this.getValueKey(option) === key)
      }
    },
    getValueLabel() {
      return (option) => {
        if (!option) return ''
        if (this.valueLabel) {
          return this.valueLabel.split('.').reduce((arr, key) => {
            if (arr[key]) return arr[key]
            return arr
          }, option)
        } else {
          return option
        }
      }
    },
    getValueKey() {
      return (option) => {
        if (!option) return null
        if (this.valueKey) {
          return this.valueKey.split('.').reduce((arr, key) => {
            if (arr[key]) return arr[key]
            return arr
          }, option)
        } else {
          return option
        }
      }
    },
    isSelected() {
      return (option) => {
        if (this.multiple) {
          return (this.modelValue || []).find(
            (selectedOptionKey) =>
              selectedOptionKey == this.getValueKey(option),
          )
        } else {
          return this.modelValue && this.modelValue == this.getValueKey(option)
        }
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.documentListener.bind(this))
  },
  beforeUnmount() {
    document.removeEventListener('click', this.documentListener.bind(this))
  },
  methods: {
    documentListener(e) {
      const select = e.target.closest(`label[data-name="${this.name}"]`)
      if (this.$refs.select !== select) {
        this.isFocused = false
      }
    },
    select(option) {
      if (this.multiple) {
        if (this.isSelected(option)) {
          this.$emit(
            'update:model-value',
            (this.modelValue || []).filter((selectedOptionKey) => {
              return selectedOptionKey !== this.getValueKey(option)
            }),
          )
        } else {
          this.$emit('update:model-value', [
            ...(this.modelValue || []),
            this.getValueKey(option),
          ])
        }
      } else {
        if (this.isSelected(option)) {
          this.$emit('update:model-value', null)
        } else {
          this.$emit('update:model-value', this.getValueKey(option))
        }
        if (document.activeElement) {
          document.activeElement.blur()
        }
        this.isFocused = false
      }
    },
    focus() {
      this.isFocused = true
      this.$emit('focus')
    },
    blur() {
      this.isFocused = false
      this.$emit('blur', this.modelValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-select {
  display: block;
  position: relative;

  &--focus {
    z-index: 1000;
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
    color: #0e1839;
  }

  &__sublabel {
    color: rgb(58, 58, 58);
    font-size: 13px;
    padding: 5px 0;
  }

  &__sign {
    margin-left: 5px;
    color: #f3574d;
  }

  &__item {
    position: relative;
  }

  &__body {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #0e1839;
    background: #fff;
    border-radius: 10px;
    padding: 14px 15px;
    transition: 0.3s linear;

    input {
      border: none;
      outline: none;
      background: transparent;
      padding: 0;
      flex-grow: 1;
      line-height: 1;
      font-size: 14px;
      font-weight: 400;
      color: #0e1839;

      &::placeholder {
        line-height: 1;
        font-size: 14px;
        font-weight: 400;
        color: #0e1839;
      }
    }
  }

  &--error &__body {
    border: 1px solid #f3574d;
  }

  &--focus &__body {
    border: 1px solid #ffcc47;
  }

  &__arrow {
    transform-origin: center;
    transition: 0.3s linear;
    transform: rotateZ(0deg);
  }

  &--focus &__arrow {
    transform: rotateZ(180deg);
  }

  &__body-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    margin-bottom: 0;
  }

  &__options {
    position: absolute;
    left: 0;
    top: 110%;
    right: 0;
    background: #fff;
    box-sizing: border-box;
    border: 1px solid #000;
    z-index: 100;
    border-radius: 10px;
    max-height: 350px;
    overflow-y: auto;
  }

  &__option {
    padding: 14px 15px;
    cursor: pointer;
    transition: 0.3s linear;

    &--selected {
      background: #ffcc47;
      color: #000;
    }
  }

  &__error {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #f3574d;
  }
}
</style>
