<template>
  <div
    class="ui-file-input"
    :class="{
      'ui-file-input--filled': modelValue || src,
      'ui-file-input--error': error,
      'ui-file-input--disabled': disabled,
    }"
  >
    <input
      :name="name"
      :id="name"
      :accept="acceptTypes"
      :required="!modelValue && required"
      :disabled="disabled"
      type="file"
      class="ui-file-input__item"
      @input="update"
    />
    <label class="ui-file-input__label" :for="name" :title="label">
      <span>{{ label }}</span>
      <span v-if="required" class="ui-file-input__sign">*</span>
    </label>
    <div class="ui-file-input__footer">
      <div class="ui-file-input__info">
        <label v-if="!modelValue && !src" :for="name" class="ui-file-input__file-types">
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <span>{{ $t('labels.upload_file', [types]) }}</span>
        </label>
        <a v-else class="ui-file-input__file-name" :href="src" target="_blank" @click.stop="">
          <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.125 14l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.847 3.847 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <span>{{ file_name }}</span>
        </a>
      </div>
      <div
        v-if="info"
        class="ui-file-input__questionarie"
        @click.stop.prevent=""
      >
        <span
          class="ui-file-input__questionarie-icon"
          v-tooltip.top-center="info"
          >?</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiFileInput',
  props: {
    modelValue: null,
    label: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    name: String,
    required: {
      type: Boolean,
      default: false,
    },
    types: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      file_name: '',
      src: '',
    }
  },
  computed: {
    acceptTypesList() {
      return this.types.split(', ').map((t) => `.${t}`)
    },
    acceptTypes() {
      return this.acceptTypesList.join(',')
    },
  },
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue && this.modelValue.src) {
          this.file_name = this.modelValue.name
          this.src = this.modelValue.src
        } else {
          this.file_name = ''
          this.src = ''
        }
      },
      immediate: true,
    }
  },
  methods: {
    update(event) {
      if (!event.target.files[0]) return
      const lastFile = event.target.files[event.target.files.length - 1]

      if (!this.acceptTypesList.some((type) => lastFile.name.includes(type))) {
        event.target.value = ''
        return
      }

      if (lastFile) {
        this.file_name = lastFile.name
        this.$emit('update:model-value', lastFile)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-file-input {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background: #fff;
  padding: 27px 50px 31px 29px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  transition: 0.3s linear;
  cursor: pointer;

  &--filled {
    background: #33ad28;
  }

  &--error {
    background: #f3574d;
  }

  &--disabled {
    cursor: unset;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }

  &--disabled &__item {
    display: none;
  }

  &__label {
    position: relative;
    display: block;
    z-index: 1;
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    transition: 0.3s linear;
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-height: 60px; */
  }

  &__sign {
    margin-left: 5px;
    color: #f3574d;
  }

  &--error &__label,
  &--filled &__label {
    color: #ffffff;
  }

  &__info {
    position: relative;
    z-index: 10;
    font-size: 12px;
    font-weight: 400;

    div {
      display: flex;
      align-items: center;
      gap: 5px;
      transition: 0.3s linear;
      animation: show 0.3s linear;

      svg {
        display: block;
      }
    }
  }

  &__file-types {
    cursor: pointer;
    color: #6b69df;
    transition: 0.3s linear;
  }

  &--error &__file-types {
    color: #ffffff;
  }

  &__file-name {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #ffffff;
    z-index: 10;

    &:hover {
      text-decoration: none;
    }
  }

  &__questionarie {
    position: absolute;
    z-index: 15;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
  }

  &__questionarie-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #e3e3e3;
    color: #7f8493;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    vertical-align: middle;
    transition: 0.3s linear;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
