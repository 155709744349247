<template>
  <label class="ui-check-box">
    <input
      type="checkbox"
      :value="value"
      :required="required"
      class="ui-check-box__input"
      @input="$emit('input', $event.target.value)"
    />
    <div class="ui-check-box__body">
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="value" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.624 5.549l-9.5 9.51-4.354-4.36 1.116-1.117 3.238 3.234 8.383-8.385 1.117 1.118z" fill="#FFCC47"/></svg>
    </div>
    <div class="ui-check-box__label">{{ label }}</div>
  </label>
</template>

<script>
export default {
  name: 'UiCheckBox',
  props: {
    value: null,
    name: String,
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
}
</script>

<style lang="scss" scoped>
.ui-check-box {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  cursor: pointer;

  &__input {
    opacity: 0;
    position: absolute;
  }

  &__body {
    width: 21px;
    height: 21px;
    border: 1px solid #ffcc47;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    svg {
      display: block;
    }
  }

  &__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    color: #000;
  }
}
</style>
