<template>
  <label
    class="ui-input"
    :class="{ 'ui-input--error': error, 'ui-input--focus': isFocused }"
  >
    <div v-if="label" class="ui-input__label">
      <span>{{ label }}</span>
      <span v-if="required" class="ui-input__sign">*</span>
    </div>
    <div class="ui-input__body">
      <input
        v-model="value"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :required="required"
        :disabled="disabled"
        @focus="focus"
        @blur="blur"
      />
    </div>
    <div v-if="sublabel" class="ui-input__sublabel">
      <span>{{ sublabel }}</span>
    </div>
    <div v-show="error" class="ui-input__error">
      {{ error }}
    </div>
  </label>
</template>

<script>
export default {
  name: 'UiInput',
  props: {
    modelValue: null,
    type: {
      type: String,
      default: 'text',
    },
    name: String,
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value', 'focus', 'blur'],
  data() {
    return {
      isFocused: false,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      },
    },
  },
  methods: {
    focus() {
      this.isFocused = true
      this.$emit('focus')
    },
    blur() {
      this.isFocused = false
      this.$emit('blur', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-input {
  display: block;

  &__label {
    font-size: 14px;
    font-weight: 400;
    color: #0e1839;
  }

  &__sublabel {
    color: rgb(58, 58, 58);
    font-size: 13px;
    padding: 5px 0;
  }

  &__sign {
    margin-left: 5px;
    color: #f3574d;
  }

  &__body {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #0e1839;
    background: #fff;
    border-radius: 10px;
    padding: 14px 15px;
    transition: 0.3s linear;

    input {
      border: none;
      outline: none;
      background: transparent;
      padding: 0;
      flex-grow: 1;
      line-height: 1;
      font-size: 14px;
      font-weight: 400;
      color: #0e1839;

      &::placeholder {
        line-height: 1;
        font-size: 14px;
        font-weight: 400;
        color: #0e1839;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &--error &__body {
    border: 1px solid #f3574d;
  }

  &--focus &__body {
    border: 1px solid #ffcc47;
  }

  &__error {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #f3574d;
  }
}
</style>
