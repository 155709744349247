<template>
  <div class="expansion-panel" :class="{ 'expansion-panel--active': isActive }">
    <div class="expansion-panel__head" @click="toggle">
      <span class="expansion-panel__title">
        {{ title }}
      </span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'expansion-panel__caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 10l-4 4-4-4" stroke="#6B69DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </div>
    <Transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-if="isActive" class="expansion-panel__body">
        <div class="expansion-panel__body-in">
          <slot />
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'expansion-panel__caret-down'" @click="toggle" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 10l-4 4-4-4" stroke="#6B69DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'ExpansionPanel',
  props: {
    title: String,
    activate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isActive: this.activate,
    }
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive
    },
    beforeEnter(el) {
      el.style.height = '0'
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.height = el.scrollHeight + 'px'
      el.style.height = '0'
    },
  },
}
</script>

<style lang="scss" scoped>
.expansion-panel {
  overflow: hidden;
  border-radius: 50px;
  background: #f9f9fe;

  &__head {
    position: relative;
    padding: 45px 84px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #0e1839;
    text-transform: uppercase;
    cursor: pointer;
    font-family: inherit;
  }

  &__caret {
    position: absolute;
    display: block;
    right: 40px;
    top: 50%;
    transform: translateY(-50%) rotateZ(0deg);
    width: 24px;
    height: 24px;
    transform-origin: center;
    transition: 0.3s ease;
  }

  &--active &__caret {
    transform: translateY(-50%) rotateZ(180deg);
  }

  &__body {
    position: relative;
    transition: 0.3s ease;
  }

  &__body-in {
    padding: 0 77px 52px;
  }

  &__caret-down {
    position: absolute;
    display: block;
    right: 25px;
    bottom: 25px;
    transform: rotateZ(135deg);
    width: 24px;
    height: 24px;
    transform-origin: center;
    cursor: pointer;
  }
}
</style>
