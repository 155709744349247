export class NCALayerClient {
	websocket
	openCallbacks
	errorCallbacks
	closeCallbacks
	msgCallbacks

	constructor() {
		this.websocket = null
		this.openCallbacks = []
		this.errorCallbacks = []
		this.closeCallbacks = []
		this.msgCallbacks = []
	}

	open() {
		return new Promise((resolve, reject) => {
			this.websocket = new WebSocket('wss://127.0.0.1:13579/')

			this.websocket.onopen = (response) => {
				console.log('onopen')
				this.openCallbacks.forEach((callback) => {
					callback(response)
				})
				if (this.websocket) resolve(this.websocket)
			}

			this.websocket.onerror = (error) => {
				console.log('onerror')
				this.errorCallbacks.forEach((callback) => {
					callback(error)
				})
				this.close()
				reject(error)
			}

			this.websocket.onclose = (closeEvent) => {
				console.log('onclose')
				this.closeCallbacks.forEach((callback) => {
					callback(closeEvent)
					if (closeEvent.wasClean) {
						this.websocket = null
					}
				})
			}

			this.websocket.onmessage = async (message) => {
				console.log('onmessage')
				const data = JSON.parse(message.data)
				if (data.code == 500) {
					this.close()
				} else if (data.responseObject) {
					this.msgCallbacks.forEach((callback) => {
						callback(data.responseObject)
					})
				}
			}
		})
	}

	async request(request) {
		this.websocket = await this.open()
		return new Promise((resolve, reject) => {
			if (!this.websocket) return ''

			this.msgCallbacks.push((msg) => {
				resolve(msg)
			})

			this.errorCallbacks.push((error) => {
				reject(error)
			})

			this.closeCallbacks.push((error) => {
				reject(error)
			})

			this.websocket.send(JSON.stringify(request))
		})
	}

	async send() {
		const request = {
			module: 'kz.gov.pki.knca.commonUtils',
			method: 'signXml',
			args: [
				'PKCS12',
				'AUTHENTICATION',
				'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
				'',
				'',
			],
		}

		return await this.request(request)
	}

	async signDoc(data) {
		const request = {
			module: 'kz.gov.pki.knca.commonUtils',
			method: 'createCAdESFromBase64',
			args: ['PKCS12', 'SIGNATURE', data, true],
		}

		return await this.request(request)
	}

	close() {
		if (this.websocket) {
			this.websocket.close()
		}
	}

	static async normalizeDataToSign(data) {
		const normalizeDataBlock = async (dataBlock) => {
			if (typeof dataBlock === 'string') {
				return dataBlock
			}

			let dataBlockArrayBuffer = dataBlock
			if (dataBlock instanceof Blob) {
				dataBlockArrayBuffer = await dataBlock.arrayBuffer()
			}

			return NCALayerClient.arrayBufferToB64(dataBlockArrayBuffer)
		}

		if (Array.isArray(data)) {
			return Promise.all(data.map(normalizeDataBlock))
		}

		return normalizeDataBlock(data)
	}

	static arrayBufferToB64(arrayBuffer) {
		const bytes = new Uint8Array(arrayBuffer)
		let binary = ''
		const len = bytes.byteLength

		for (let i = 0; i < len; i += 1) {
			binary += String.fromCharCode(bytes[i])
		}

		return window.btoa(binary)
	}
}

export const NCALayerClientIns = new NCALayerClient()
