<template>
  <UiSelect
    :model-value="value"
    :name="'MainInformationDebtsSelect'"
    :label="$t('labels.certificateOfDebts')"
    :placeholder="$t('labels.certificateOfDebts')"
    :options="_getCertificateOfDebts"
    :required="required"
    :disabled="disabled"
    :error="error"
    :value-label="'label'"
    :value-key="'value'"
    @update:model-value="update"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import UiSelect from '@/components/ui/UiSelect.vue'

export default {
  name: 'MainInformationDebtsSelect',
  components: { UiSelect },
  props: {
    value: null,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  computed: {
    ...mapGetters(['_getCertificateOfDebts']),
  },
  mounted() {
    this._setUser()
  },
  methods: {
    ...mapActions(['_setUser']),
    update(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
